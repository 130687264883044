.ccccn .btn-circle {
  background-color: #4d4c4f;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  line-height: normal;
  margin: 5px;
  outline: none !important;
  border: none;
  z-index: 5;
}

.ccccn .mob-btn {
  width: 12vw;
  height: 12vw;
}

.ccccn .mob-icon {
  height: 8vw;
  width: 8vw;
}

.ccccn .btn-circle.red {
  background-color: #ec4c3a;
}

.ccccn button:active {
  outline: none !important;
  border: none;
}

.ccccn .end-call-icon {
  margin: 0 auto;
  background: url("../../icons/icon_hangup.svg");
  height: 2.5vw;
  width: 2.5vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.ccccn .audio-mute {
  margin: 0 auto;
  background: url("../../icons/icon_audio_mute.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .audio-un-mute {
  margin: 0 auto;
  background: url("../../icons/icon_audio_unmute.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .video-un-mute {
  margin: 0 auto;
  background: url("../../icons/icon_video_unmute.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .video-mute {
  margin: 0 auto;
  background: url("../../icons/icon_video_mute.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .fileShare {
  margin: 0 auto;
  background: url("../../icons/file_share.svg");
  height: 3vw;
  width: 3vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .screen-share-on {
  margin: 0 auto;
  background: url("../../icons/screenshare-enabled.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .screen-share-off {
  margin: 0 auto;
  background: url("../../icons/screen_share_disabled.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .display-mode-change {
  margin: 0 auto;
  background: url("../../icons/change_mode.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .recording {
  margin: 0 auto;
  background: url("../../icons/recording.svg");
  height: 1.5vw;
  width: 1.5vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.ccccn .stop-recording {
  margin: 0 auto;
  background: url("../../icons/cross-inside-circle.svg");
  height: 3vw;
  width: 3vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .display-mode-grid {
  margin: 0 auto;
  background: url("../../icons/grid_mode.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .button-container {
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: center;
  padding: 0.2%;
}

.ccccn .button-background2 {
  background-color: rgba(46, 47, 48, 0.6);
  border-radius: 25px;
}

.ccccn .transparent {
  opacity: 0.4;
}

.ccccn .video-source-list {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.ccccn .change-source-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  height: 1.5vw;
  width: 1.5vw;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
}

.ccccn .dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.ccccn .dropdown {
  position: relative;
  display: inline-block;
}

.ccccn .dropdown-content {
  display: none;
  position: absolute;
  background-color: #555;
  min-width: 180px;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  border-radius: 8px;
}

.ccccn .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.ccccn .dropdown-content a:hover {
  background-color: #555;
}

.ccccn .dropdown:hover .dropdown-content {
  display: block;
}

.ccccn .dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.ccccn .more-option {
  margin: 0 auto;
  background: url("../../icons/record.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.ccccn .streaming {
  margin: 0 auto;
  background: url("../../icons/streaming.svg");
  height: 1.5vw;
  width: 1.5vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.ccccn .padding-right-12 {
  right: 12%;
}
.ccccn .padding-right-0 {
  right: 0%;
}
