@import url(https://fonts.googleapis.com/css?family=Inter);
.ccccn .cc-App {
  text-align: center;
  height: 100%;
  position: relative;
}

.ccccn .cc-App-header {
  /* background-color: #282c34; */
  justify-content: center;
  height: 100%;
}

/* .tool {
  cursor: help;
  position: relative;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
} */

/*== common styles for both parts of tool tip ==*/
/* .tool::before,
.tool::after {
  left: 50%;
  position: absolute;
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 100;
} */

/*== pointer tip ==*/
/* .tool::before {
  border-style: solid;
  border-width: 1em 0.75em 0 0.75em;
  border-color: #3e474f transparent transparent transparent;
  bottom: 100%;
  content: "";
  margin-left: -0.5em;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26),
    opacity 0.65s 0.5s;
  transform: scale(0.6) translateY(-90%);
} */

/*== speech bubble ==*/
/* .tool::after {
  background: #3e474f;
  border-radius: 0.25em;
  bottom: 180%;
  color: #edeff0;
  content: attr(data-tip);
  margin-left: -8.75em;
  padding: 1em;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
  transform: scale(0.6) translateY(50%);
  width: 17.5em;
} */

@media (max-width: 760px) {
  .ccccn .tool::after {
    font-size: 0.75em;
    margin-left: -5em;
    width: 10em;
  }
}

#cc-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ccccn .btn-circle {
  background-color: #4d4c4f;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  line-height: normal;
  margin: 5px;
  outline: none !important;
  border: none;
  z-index: 5;
}

.ccccn .mob-btn {
  width: 12vw;
  height: 12vw;
}

.ccccn .mob-icon {
  height: 8vw;
  width: 8vw;
}

.ccccn .btn-circle.red {
  background-color: #ec4c3a;
}

.ccccn button:active {
  outline: none !important;
  border: none;
}

.ccccn .end-call-icon {
  margin: 0 auto;
  background: url(/static/media/icon_hangup.edfcc97b.svg);
  height: 2.5vw;
  width: 2.5vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.ccccn .audio-mute {
  margin: 0 auto;
  background: url(/static/media/icon_audio_mute.f06fda6a.svg);
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .audio-un-mute {
  margin: 0 auto;
  background: url(/static/media/icon_audio_unmute.069caed9.svg);
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .video-un-mute {
  margin: 0 auto;
  background: url(/static/media/icon_video_unmute.433e7b83.svg);
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .video-mute {
  margin: 0 auto;
  background: url(/static/media/icon_video_mute.eab333cc.svg);
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .fileShare {
  margin: 0 auto;
  background: url(/static/media/file_share.9f1518b2.svg);
  height: 3vw;
  width: 3vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .screen-share-on {
  margin: 0 auto;
  background: url(/static/media/screenshare-enabled.e3234346.svg);
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .screen-share-off {
  margin: 0 auto;
  background: url(/static/media/screen_share_disabled.bbede6b0.svg);
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .display-mode-change {
  margin: 0 auto;
  background: url(/static/media/change_mode.c83f84d4.svg);
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .recording {
  margin: 0 auto;
  background: url(/static/media/recording.45f684d7.svg);
  height: 1.5vw;
  width: 1.5vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.ccccn .stop-recording {
  margin: 0 auto;
  background: url(/static/media/cross-inside-circle.5e98aa67.svg);
  height: 3vw;
  width: 3vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .display-mode-grid {
  margin: 0 auto;
  background: url(/static/media/grid_mode.00bc0392.svg);
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.ccccn .button-container {
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: center;
  padding: 0.2%;
}

.ccccn .button-background2 {
  background-color: rgba(46, 47, 48, 0.6);
  border-radius: 25px;
}

.ccccn .transparent {
  opacity: 0.4;
}

.ccccn .video-source-list {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.ccccn .change-source-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  height: 1.5vw;
  width: 1.5vw;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
}

.ccccn .dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.ccccn .dropdown {
  position: relative;
  display: inline-block;
}

.ccccn .dropdown-content {
  display: none;
  position: absolute;
  background-color: #555;
  min-width: 180px;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  border-radius: 8px;
}

.ccccn .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.ccccn .dropdown-content a:hover {
  background-color: #555;
}

.ccccn .dropdown:hover .dropdown-content {
  display: block;
}

.ccccn .dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.ccccn .more-option {
  margin: 0 auto;
  background: url(/static/media/record.a447c22a.svg);
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.ccccn .streaming {
  margin: 0 auto;
  background: url(/static/media/streaming.7c6c0510.svg);
  height: 1.5vw;
  width: 1.5vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.ccccn .padding-right-12 {
  right: 12%;
}
.ccccn .padding-right-0 {
  right: 0%;
}

.ccccn .Main-Video {
  min-width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.ccccn .button-bar {
  position: absolute;
  left: 0;
  bottom: 0;
}

.ccccn .stack-top {
  z-index: 9;
  margin: 5px;
}

.ccccn .thumb-container {
  right: 0;
  top: 0;
  z-index: 10;
  margin: unset;
  -webkit-padding-start: unset;
          padding-inline-start: unset;
  display: inline;
}

.ccccn .cc-video-container {
  width: 25vh;
  height: 25vh;
  height: calc(100 * var(--vh));
}

.ccccn #main-video video {
  cursor: initial;
}

.ccccn .main-video-container {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: fixed; */
  /* transform: rotateY(180deg) */
}

.ccccn .main-video-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ccccn .main-video-avatar-center {
  /* height: 85vh; */
}

.ccccn .Main-Video-Mob {
  background-color: #000000;
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: fixed; */
}

.ccccn .thumb-video-container {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  border-radius: 0.7rem;
  justify-content: center;
}

.ccccn .thumb-mobile {
  width: 15vh;
  height: 15vh;
  margin-bottom: 5px;
}

.ccccn .Main-Video-Background {
  min-width: 100%;
  min-height: 100%;
}

.ccccn .change-mode {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.ccccn .grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@-webkit-keyframes slide-out-right {
  from {
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    display: none;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slide-out-right {
  from {
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    display: none;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes slide-out-left {
  from {
    display: none;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-out-left {
  from {
    display: none;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.ccccn .slide-out-right {
  -webkit-animation-name: slide-out-right;
  animation-name: slide-out-right;
}

.ccccn .slide-out-left {
  -webkit-animation-name: slide-out-left;
  animation-name: slide-out-left;
}

.ccccn .animated {
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fade-in-down {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fade-in-down {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.ccccn .fade-in-down {
  -webkit-animation-name: fade-in-down;
  animation-name: fade-in-down;
}

@-webkit-keyframes fade-in-up {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-in-up {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.ccccn .fade-in-up {
  -webkit-animation-name: fade-in-up;
  animation-name: fade-in-up;
}

.ccccn .Main-Video__bottom-user-name {
  position: absolute;
  z-index: 0;
  font-size: 14px;
  border-radius: 0.375rem;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  margin-left: 0.3rem;
  margin-bottom: 0.3rem;
  top: 0;
  right: 0;
}
@media (max-width: 400px) {
  .ccccn .Main-Video__bottom-user-name {
    left: 0px;
    margin-bottom: 0;
    margin-left: 0;
  }
  /* .local-video-stream video {
    width: 100px;
  } */
  .ccccn .local-video-stream {
    display: flex;
    justify-content: flex-end;
  }
}

.ccccn .local-video-stream {
  width: 200px;
}

.ccccn .popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

/* The actual popup */
.ccccn .popup .popup-text {
  width: 250px;

  background-color: #555;
  color: #fff;
  /* text-align: center; */
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}
.ccccn .popup .popup-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.ccccn .popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ccccn input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.ccccn input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #458b00;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.ccccn .grid-shadow {
  box-shadow: 15px 0px 10px 5px rgba(0, 0, 0, 0.41);
}

.ccccn .lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ccccn .lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  -webkit-animation: lds-default 1.2s linear infinite;
          animation: lds-default 1.2s linear infinite;
}
.ccccn .lds-default div:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.ccccn .lds-default div:nth-child(2) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.ccccn .lds-default div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.ccccn .lds-default div:nth-child(4) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.ccccn .lds-default div:nth-child(5) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.ccccn .lds-default div:nth-child(6) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.ccccn .lds-default div:nth-child(7) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.ccccn .lds-default div:nth-child(8) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.ccccn .lds-default div:nth-child(9) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.ccccn .lds-default div:nth-child(10) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.ccccn .lds-default div:nth-child(11) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.ccccn .lds-default div:nth-child(12) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@-webkit-keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

.ccccn .main-tile-container {
  height: 100%;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-start;
}

.ccccn .main-tile-container-portrait {
  height: 100%;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-start;
}

.ccccn .tile-main-video-container {
  display: flex;
  flex: 1 1;
  height: 89%;
  margin-top: 5px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 5%;
  position: relative;
  justify-content: center;
  background-color: #000;
  border-radius: 10px;
}

.ccccn .tile-main-video-container-portrait {
  display: flex;
  width: 90%;
  height: 63%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  margin-top: 5%;
  position: relative;
  justify-content: center;
  background-color: #000;
  border-radius: 20;
}

.ccccn .tile-list-container-portrait {
  width: 100vw;
  height: 20%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0.5%;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow-x: auto;
}
.ccccn .tile-list-container {
  width: 15%;
  padding-left: 0%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 0.5%;
  overflow-y: auto;
}

.ccccn .local-spotlight-stream-container {
  width: 12vw;
}
.ccccn .grid-container {
  position: relative;
  display: flex;
  height: 100%;
  padding-left: 1px;
  padding-right: 1px;
  margin-left: 0.5%;
  margin-right: 0.5%;

  justify-content: center;
  align-items: center;
}
.ccccn .main-video-name {
  color: #fff;
  position: absolute;
  top: 5px;
  left: 5px;
}
.ccccn .screen-share-text {
  color: #fff;
}

.ccccn .aspect-ratio-1 {
  aspect-ratio: 1;
}

/* NEW UI  */
.ccccn .cc-main-container {
  /* height: 100vh;
  height: calc(100 * var(--vh));
  width: 100vw; */
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1c2226;
  position: relative;
}

.ccccn .main-container-recording-icon {
  color: red;
  margin-right: 4px;
}

@-webkit-keyframes rec-blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes rec-blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.ccccn .main-container-recording-icon {
  text-decoration: blink;
  -webkit-animation-name: rec-blinker;
          animation-name: rec-blinker;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.ccccn .layout {
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1 1;
  position: relative;
  flex-direction: row;
}

.ccccn .main-container-recording-text {
  color: rgb(255, 255, 255);
}

.ccccn .loading-container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.ccccn .reconnect-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #000000d0;
}

.ccccn .loading-text {
  margin-top: 8px;
  color: rgb(255, 255, 255);
}

.ccccn .scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ccccn .scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  overflow-y: auto; /* Firefox */
}

.ccccn .mainVideoContainer {
  /* height: calc(calc(100 * var(--vh)) - 80px); */
  height: calc(100% - var(--ccccn-bottom-button-container-height));
  display: flex;
  z-index: 0;
}

.ccccn .safari-video-container {
  min-width: 25vh;

  width: 0;
}

/* for mobile specific css */
@media (max-width: 600px) {
  .ccccn .recording-text-container {
    position: absolute;
    left: 16px;
    top: 16px;
    bottom: auto;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media (min-width: 600px) {
  .ccccn .recording-text-container {
    position: absolute;
    left: 16px;
    bottom: 24px;
    z-index: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.ccccn .cc-name-label {
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

:root {
  --ccccn-no-of-tiles: 4;
}

@media (hover: none) {
  :root {
    --ccccn-no-of-tiles: 2;
  }
}

.ccccn .local-video-container {
  position: fixed;
  height: 20vh;
  width: 15vw;
  aspect-ratio: 3/2;
  min-width: 150px;
  background-color: rgb(36, 43, 48);
  align-items: center;
  justify-content: center;
  display: flex;
  bottom: 8px;
  bottom: var(--ccccn-bottom-button-container-height);
  right: var(--ccccn-padding-around-video);
  -webkit-user-select: none;
          user-select: none;
  cursor: grab;
}

.ccccn .cc-video-container {
  display: flex;
  flex: 1 1;
  background-color: rgb(36, 43, 48);
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  height: 100%;
  width: 100%;
  transition: all 5s ease 0s;
  border-radius: 8px;
  box-shadow: rgba(36, 43, 48, 0.7);
}

.ccccn .video-container-user-name {
  position: absolute;
  flex-direction: row;
  display: flex;
  left: 8px;
  top: 8px;
  right: 30%;
  text-align: left;
}

.ccccn .video-container-user-name-text {
  color: rgb(255, 255, 255);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ccccn .side-bar {
  display: flex;
  flex: 1 1;
  height: 100%;
  width: 100%;
  flex-direction: row;
}
.ccccn .side-bar-container {
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
}

.ccccn .side-bar-main-user-container {
  display: flex;
  flex: 1 1;
  padding-top: 8px;
  padding-left: 8px;
}

.ccccn .side-bar-main-user-video {
  display: flex;
  flex: 1 1;
  position: relative;
}
.ccccn .video-element {
  /* aspect-ratio: 1; */
}
.ccccn .side-bar-main-user-video-pin-icon {
  position: absolute;
  z-index: 10;
  color: rgb(255, 255, 255);
  height: 5%;
  top: 8px;
  right: 8px;
}

.ccccn .side-bar-tile-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20%;
  max-width: 20vh;
  min-width: 100px;
  margin-left: 8px;
  margin-right: 8px;
}
.ccccn .side-bar-tile-wrapper {
  display: flex;
  flex: 1 1;
  position: relative;
  max-height: calc(20% - 8px);
  max-height: calc(100% / 4 - 8px);
  max-height: calc(100% / var(--ccccn-no-of-tiles) - 8px);
  width: 100%;
  max-width: 20vh;
  min-width: 100px;
  margin-top: 8px;
}

.ccccn .side-bar-tile-container {
  display: flex;
  flex: 1 1;
  background-color: rgb(36, 43, 48);
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  flex-direction: column;
}
.ccccn .side-bar-tile-wrapper:hover #pin {
  display: flex;
}

.ccccn .side-bar-tile-more-people-icon-container {
  height: 30%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ccccn .side-bar-tile-more-people-icon-badge {
  color: rgb(255, 255, 255);
  margin: 4px;
}
.ccccn .side-bar-tile-more-text {
  color: rgb(255, 255, 255);
}
.ccccn .side-bar-tile-pin-icon-container {
  position: absolute;
  flex: 1 1;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10000;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  display: none;
  cursor: pointer;
}
.ccccn .side-bar-tile-pin-icon {
  width: 20%;
}

.ccccn .side-bar-tile-audio-icon {
  position: absolute;
  z-index: 10;
  top: 8px;
  right: 8px;
}

.ccccn .single-main-container {
  display: flex;
  flex: 1 1;
  height: 100%;
  padding: 16px;
}

.ccccn .spotlight-main-container {
  display: flex;
  position: relative;
  flex: 1 1;
  height: 100%;
  padding-top: var(--ccccn-padding-around-video);
  padding-left: var(--ccccn-padding-around-video);
  padding-right: var(--ccccn-padding-around-video);
}

.ccccn .tile-main-container {
  flex: 1 1;
  display: flex;
  height: 100%;
  background-color: #1c2226;
}
.ccccn .tile-more-people-icon-container {
  height: 30%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ccccn .tile-more-people-badge {
  color: rgb(255, 255, 255);
  margin: 4px;
}
.ccccn .tile-more-people-text {
  color: rgb(255, 255, 255);
}
.ccccn .tile-list-wrapper {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 4px;
}

.ccccn .tile-wrapper {
  display: flex;
  flex: 1 1;
  background-color: rgb(36, 43, 48);
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  flex-direction: column;
}

.ccccn .tile-pinned-ui-main-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-height: 88vh;
}

.ccccn .tile-pinned-ui-main-video-container {
  flex: 1 1;
  display: flex;
  position: relative;
  max-height: 65%;
  margin: 8px;
}

.ccccn .tile-pinned-ui-un-pin-icon {
  z-index: 1000;
  color: rgb(255, 255, 255);
}

.ccccn .tile-pinned-ui-other-user-container {
  height: 30%;
  display: flex;
  width: 100%;
  padding-left: 4px;
}
.ccccn .tile-pinned-ui-more-user-container {
  max-width: 25%;
  flex: 1 1;
  display: flex;
  margin-right: 8px;
}
.ccccn .tile-pinned-ui-more-user-wrapper {
  display: flex;
  flex: 1 1;
  background-color: rgb(36, 43, 48);
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.ccccn .tile-pinned-ui-remaining-user {
  max-width: 25%;
  flex: 1 1;
  display: flex;
  position: relative;
  margin-right: 4px;
}

.ccccn .show-border {
  border-width: 2px;
}
.ccccn .pin-image {
  height: 20px;
  cursor: pointer;
}

.ccccn .grid-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1 1;
  width: 100%;
  padding-top: 0.5%;
  margin-bottom: 0.5%;
}

@media (max-width: 600px) {
  .ccccn .side-bar-container {
    flex-direction: column;
  }
  .ccccn .side-bar-main-user-container {
    padding-right: 8px;
    height: 80%;
  }
  .ccccn .side-bar-tile-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 20%;
    width: 100vw;
    max-width: none;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 8px;
    padding-right: 8px;
    grid-gap: 8px;
    gap: 8px;
  }
  .ccccn .side-bar-tile-wrapper {
    max-width: 32vw;
    width: auto;
    aspect-ratio: 1 / 1;
    max-height: 100%;
  }
}

.ccccn .pin-option {
  display: none;
  position: absolute;
  top: 9px;
  border-radius: 50%;
  padding: 5px;
  height: 30px;
  width: 30px;
  aspect-ratio: 1px;
  right: 8px;
  z-index: 10;
  justify-content: center;
  align-items: center;
  background-color: #707070;
  cursor: pointer;
}
.ccccn .grid-box {
  display: flex;
  flex: 1 1;
  border-width: 2px;
  margin-right: 5px;
  border-color: transparent;
  border-radius: 8px;
  position: relative;
}
.ccccn .grid-box:hover .pin-option {
  display: flex;
}

.ccccn .grid-main-container {
  flex: 1 1;
  display: flex;
}

.ccccn .show-border {
  border-width: 2px;
  border-color: #1e79e7;
}

.ccccn .grid-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1 1;
  width: 100%;
  padding-top: 0.5%;
  margin-bottom: 0.5%;
}

.ccccn .red {
  color: red;
}

.ccccn .flipped-video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.ccccn .paginated-pinned-view {
  display: flex;
  height: 100%;
  width: 100%;
}

.ccccn .paginated-pinned-view-text {
  justify-content: center;
  align-items: center;
  background-color: #000;
}

@media all and (max-width: 520px) {
  .ccccn .awssld__controls {
    visibility: visible !important;
  }
}
.ccccn .awssld__controls button:focus {
  outline: none;
}

.ccccn .awssld__bullets {
  position: absolute;
  bottom: 10px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.ccccn .awssld__bullets button {
  height: 8px !important;
  width: 8px !important;
  outline: none !important;
}

.ccccn .awssld__bullets--active {
  background-color: #1e79e7 !important;
}

.ccccn .awssld__next {
  width: 7% !important;
}

.ccccn .awssld__prev {
  width: 7% !important;
}

.ccccn .awssld__controls__arrow-right {
  height: 20% !important;
}
.ccccn .awssld__controls__arrow-left:before,
.ccccn .awssld__controls__arrow-left:after,
.ccccn .awssld__controls__arrow-right:before,
.ccccn .awssld__controls__arrow-right:after {
  background-color: rgb(49, 56, 62) !important;
}
.ccccn .awssld__controls__arrow-left {
  height: 20% !important;
}

.ccccn .paginated-container {
  display: flex;
  flex: 1 1;
  padding: 8;
}

.ccccn .absolute-top-left {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.ccccn .absolute-top-right {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.ccccn .absolute-bottom-left {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ccccn .absolute-bottom-right {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.ccccn .awssld__bullets {
  width: 50% !important;
  margin-left: 25%;
  margin-right: 25%;
}

.ccccn .bottom-buttons-icon-color {
  color: #fff;
  height: 24px;
  width: 24px;
}
.ccccn .bottom-buttons-icon-button {
  border: none;
  outline: none;
  margin-right: 4px;
}
.ccccn .cc-bottom-buttons-icon-container {
  min-height: 50px;
  min-width: 50px;
  height: 7vh;
  width: 7vh;
  background-color: #31383e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ccccn .cc-bottom-buttons-with-padding {
  height: 80px;
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  background-image: linear-gradient(rgb(28, 34, 38), rgb(19, 23, 26));
  padding-right: 20vh;
}

@media (max-width: 600px) {
  .ccccn .cc-bottom-buttons-with-padding {
    padding-right: 0px;
  }
}

.ccccn .cc-bottom-buttons {
  height: var(--ccccn-bottom-button-container-height);
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgb(28, 34, 38), rgb(19, 23, 26));
  position: relative;
}

.ccccn .bottom-buttons-other-options {
  position: absolute;
  display: flex;
  right: 8px;
  bottom: 8px;
}

.ccccn .dialog-form {
  padding: 16px;
}
.ccccn .settings-dropdown-style {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}

.ccccn .settings-icon-button {
  border: none;
  outline: none;
}

.ccccn .color-white {
  color: #fff;
}

.ccccn .setting-menu-icon-container {
  margin-right: 16px;
}

.ccccn .track-error-container {
  position: absolute;
  bottom: 0;
  right: -4px;
  background-color: #31383e;
  border-radius: 50%;
  aspect-ratio: 1px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ccccn .track-error-icon {
  aspect-ratio: 1px;
  height: 16px;
  width: 16px;
  color: #fff;
}

.ccccn .cc-audio-icon-container-muted {
  background-color: #d93438;
}

.ccccn .cc-end-call-icon-container {
  background-color: #d93438;
}

.ccccn .cc-video-icon-container-muted {
  background-color: #d93438;
}

.ccccn .main-video-container video {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.ccccn .Main-Video-Mob video {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 11px 18px -4px rgba(0, 0, 0, 0.75);
}

.ccccn .thumb-video-container video {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.ccccn .rotate-180 {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.ccccn .Main-Video-Background video {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.ccccn .grid-container video {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.ccccn .video-thumb {
  height: 300px;
  width: 300px;
  min-width: 300px;
  min-height: 300px;
}

.ccccn .remote-audio {
  min-width: 0px;
  min-height: 0px;
  display: none;
}

.ccccn .stream-component {
  position: absolute;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  border-bottom-right-radius: 4px;
  opacity: 0.5;
  z-index: 5;
}

.ccccn .user-icons {
  right: 5px;
  background: none;
  opacity: 0.7;
}

.ccccn .mob-font {
  font-size: small;
}

/* .ccccn p {
  margin: 0;
} */

.ccccn .pin-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #282c34;
  z-index: 2;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ccccn .audioindicator {
  display: inline-block;
  display: flex;
  justify-content: space-around;
  width: 24px;
  border: none;
}

.ccccn .audiodot-top,
.ccccn .audiodot-bottom,
.ccccn .audiodot-middle {
  opacity: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background: rgba(9, 36, 77, 0.9);
  margin: 1px 0 1px 0;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
}

.ccccn span.audiodot-top,
.ccccn span.audiodot-bottom,
.ccccn span.audiodot-middle {
  content: "";
  opacity: 1;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
  background: #44a5ff;
}
.ccccn .hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ccccn .hide-scroll::-webkit-scrollbar {
  display: none;
}

.ccccn .tile-container-mob {
  width: 15vh;
  position: relative;
  margin-left: 10px;
}
.ccccn .tile-container {
  width: 90%;
  position: relative;
  aspect-ratio: 1;
  min-height: 10.5vw;
  max-height: 10.5vw;
  background-color: #000000;
  margin-bottom: 10px;
  border-radius: 0.7rem;
}

/* Hide scrollbar for IE, Edge and Firefox */

/* html {
  overflow: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */
.ccccn .user-stats {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 250ms ease-out;
  left: 0;
  display: flex;
  align-items: flex-end;
  z-index: 1;
}

.ccccn .video-tiles:hover .user-stats {
  opacity: 1;
  background-color: rgb(0, 0, 0, 0.7);
}

.ccccn [data-id] {
  text-transform: capitalize;
}

.ccccn *::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  border-radius: 10px;
  background-color: #2d3748;
}

.ccccn *::-webkit-scrollbar {
  width: 12px;
  background-color: #2d3748;
}

.ccccn *::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1a202c;
}

@media (max-width: 991px) {
  .ccccn .hidden-md-down {
    display: none !important;
  }
}

:root {
  --ccccn-bottom-button-container-height: 80px;
  --ccccn-padding-around-video: 8px;
}
.ccccn * {
  font-family: Inter;
}

.ccccn .cc-snackbar-root-override {
  position: absolute !important;
}

p {
  margin: 0;
}

.ccccn button {
  background-color: transparent;
  background-image: none;
}

.ccccn button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

.ccccn ol,
.ccccn ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ccccn *,
.ccccn ::before,
.ccccn ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}

.ccccn hr {
  border-top-width: 1px;
}

.ccccn img {
  border-style: solid;
}

.ccccn button,
.ccccn [role="button"] {
  cursor: pointer;
}

.ccccn button,
.ccccn input,
.ccccn optgroup,
.ccccn select,
.ccccn textarea {
  padding: 0;
}

.ccccn pre,
.ccccn code,
.ccccn kbd,
.ccccn samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.ccccn img,
.ccccn svg,
.ccccn video,
.ccccn canvas,
.ccccn audio,
.ccccn iframe,
.ccccn embed,
.ccccn object {
  display: block;
}

.ccccn img,
.ccccn video {
  max-width: 100%;
}

