p {
  margin: 0;
}

.ccccn button {
  background-color: transparent;
  background-image: none;
}

.ccccn button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

.ccccn ol,
.ccccn ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ccccn *,
.ccccn ::before,
.ccccn ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}

.ccccn hr {
  border-top-width: 1px;
}

.ccccn img {
  border-style: solid;
}

.ccccn button,
.ccccn [role="button"] {
  cursor: pointer;
}

.ccccn button,
.ccccn input,
.ccccn optgroup,
.ccccn select,
.ccccn textarea {
  padding: 0;
}

.ccccn pre,
.ccccn code,
.ccccn kbd,
.ccccn samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.ccccn img,
.ccccn svg,
.ccccn video,
.ccccn canvas,
.ccccn audio,
.ccccn iframe,
.ccccn embed,
.ccccn object {
  display: block;
}

.ccccn img,
.ccccn video {
  max-width: 100%;
}
