:root {
  --ccccn-no-of-tiles: 4;
}

@media (hover: none) {
  :root {
    --ccccn-no-of-tiles: 2;
  }
}

.ccccn .local-video-container {
  position: fixed;
  height: 20vh;
  width: 15vw;
  aspect-ratio: 3/2;
  min-width: 150px;
  background-color: rgb(36, 43, 48);
  align-items: center;
  justify-content: center;
  display: flex;
  bottom: 8px;
  bottom: var(--ccccn-bottom-button-container-height);
  right: var(--ccccn-padding-around-video);
  user-select: none;
  cursor: grab;
}

.ccccn .cc-video-container {
  display: flex;
  flex: 1 1 0%;
  background-color: rgb(36, 43, 48);
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  height: 100%;
  width: 100%;
  transition: all 5s ease 0s;
  border-radius: 8px;
  box-shadow: rgba(36, 43, 48, 0.7);
}

.ccccn .video-container-user-name {
  position: absolute;
  flex-direction: row;
  display: flex;
  left: 8px;
  top: 8px;
  right: 30%;
  text-align: left;
}

.ccccn .video-container-user-name-text {
  color: rgb(255, 255, 255);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ccccn .side-bar {
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  width: 100%;
  flex-direction: row;
}
.ccccn .side-bar-container {
  display: flex;
  flex: 1 1 0%;
  width: 100%;
  height: 100%;
}

.ccccn .side-bar-main-user-container {
  display: flex;
  flex: 1 1 0%;
  padding-top: 8px;
  padding-left: 8px;
}

.ccccn .side-bar-main-user-video {
  display: flex;
  flex: 1 1 0%;
  position: relative;
}
.ccccn .video-element {
  /* aspect-ratio: 1; */
}
.ccccn .side-bar-main-user-video-pin-icon {
  position: absolute;
  z-index: 10;
  color: rgb(255, 255, 255);
  height: 5%;
  top: 8px;
  right: 8px;
}

.ccccn .side-bar-tile-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20%;
  max-width: 20vh;
  min-width: 100px;
  margin-left: 8px;
  margin-right: 8px;
}
.ccccn .side-bar-tile-wrapper {
  display: flex;
  flex: 1 1 0%;
  position: relative;
  max-height: calc(20% - 8px);
  max-height: calc(100% / var(--ccccn-no-of-tiles) - 8px);
  width: 100%;
  max-width: 20vh;
  min-width: 100px;
  margin-top: 8px;
}

.ccccn .side-bar-tile-container {
  display: flex;
  flex: 1 1 0%;
  background-color: rgb(36, 43, 48);
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  flex-direction: column;
}
.ccccn .side-bar-tile-wrapper:hover #pin {
  display: flex;
}

.ccccn .side-bar-tile-more-people-icon-container {
  height: 30%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ccccn .side-bar-tile-more-people-icon-badge {
  color: rgb(255, 255, 255);
  margin: 4px;
}
.ccccn .side-bar-tile-more-text {
  color: rgb(255, 255, 255);
}
.ccccn .side-bar-tile-pin-icon-container {
  position: absolute;
  flex: 1 1 0%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10000;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  display: none;
  cursor: pointer;
}
.ccccn .side-bar-tile-pin-icon {
  width: 20%;
}

.ccccn .side-bar-tile-audio-icon {
  position: absolute;
  z-index: 10;
  top: 8px;
  right: 8px;
}

.ccccn .single-main-container {
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  padding: 16px;
}

.ccccn .spotlight-main-container {
  display: flex;
  position: relative;
  flex: 1 1 0%;
  height: 100%;
  padding-top: var(--ccccn-padding-around-video);
  padding-left: var(--ccccn-padding-around-video);
  padding-right: var(--ccccn-padding-around-video);
}

.ccccn .tile-main-container {
  flex: 1;
  display: flex;
  height: 100%;
  background-color: #1c2226;
}
.ccccn .tile-more-people-icon-container {
  height: 30%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ccccn .tile-more-people-badge {
  color: rgb(255, 255, 255);
  margin: 4px;
}
.ccccn .tile-more-people-text {
  color: rgb(255, 255, 255);
}
.ccccn .tile-list-wrapper {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 4px;
}

.ccccn .tile-wrapper {
  display: flex;
  flex: 1 1 0%;
  background-color: rgb(36, 43, 48);
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  flex-direction: column;
}

.ccccn .tile-pinned-ui-main-container {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  max-height: 88vh;
}

.ccccn .tile-pinned-ui-main-video-container {
  flex: 1 1 0%;
  display: flex;
  position: relative;
  max-height: 65%;
  margin: 8px;
}

.ccccn .tile-pinned-ui-un-pin-icon {
  z-index: 1000;
  color: rgb(255, 255, 255);
}

.ccccn .tile-pinned-ui-other-user-container {
  height: 30%;
  display: flex;
  width: 100%;
  padding-left: 4px;
}
.ccccn .tile-pinned-ui-more-user-container {
  max-width: 25%;
  flex: 1 1 0%;
  display: flex;
  margin-right: 8px;
}
.ccccn .tile-pinned-ui-more-user-wrapper {
  display: flex;
  flex: 1 1 0%;
  background-color: rgb(36, 43, 48);
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.ccccn .tile-pinned-ui-remaining-user {
  max-width: 25%;
  flex: 1 1 0%;
  display: flex;
  position: relative;
  margin-right: 4px;
}

.ccccn .show-border {
  border-width: 2px;
}
.ccccn .pin-image {
  height: 20px;
  cursor: pointer;
}

.ccccn .grid-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  width: 100%;
  padding-top: 0.5%;
  margin-bottom: 0.5%;
}

@media (max-width: 600px) {
  .ccccn .side-bar-container {
    flex-direction: column;
  }
  .ccccn .side-bar-main-user-container {
    padding-right: 8px;
    height: 80%;
  }
  .ccccn .side-bar-tile-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 20%;
    width: 100vw;
    max-width: none;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 8px;
    padding-right: 8px;
    gap: 8px;
  }
  .ccccn .side-bar-tile-wrapper {
    max-width: 32vw;
    width: auto;
    aspect-ratio: 1 / 1;
    max-height: 100%;
  }
}

.ccccn .pin-option {
  display: none;
  position: absolute;
  top: 9px;
  border-radius: 50%;
  padding: 5px;
  height: 30px;
  width: 30px;
  aspect-ratio: 1px;
  right: 8px;
  z-index: 10;
  justify-content: center;
  align-items: center;
  background-color: #707070;
  cursor: pointer;
}
.ccccn .grid-box {
  display: flex;
  flex: 1;
  border-width: 2px;
  margin-right: 5px;
  border-color: transparent;
  border-radius: 8px;
  position: relative;
}
.ccccn .grid-box:hover .pin-option {
  display: flex;
}

.ccccn .grid-main-container {
  flex: 1;
  display: flex;
}

.ccccn .show-border {
  border-width: 2px;
  border-color: #1e79e7;
}

.ccccn .grid-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  width: 100%;
  padding-top: 0.5%;
  margin-bottom: 0.5%;
}

.ccccn .red {
  color: red;
}

.ccccn .flipped-video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.ccccn .paginated-pinned-view {
  display: flex;
  height: 100%;
  width: 100%;
}

.ccccn .paginated-pinned-view-text {
  justify-content: center;
  align-items: center;
  background-color: #000;
}

@media all and (max-width: 520px) {
  .ccccn .awssld__controls {
    visibility: visible !important;
  }
}
.ccccn .awssld__controls button:focus {
  outline: none;
}

.ccccn .awssld__bullets {
  position: absolute;
  bottom: 10px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.ccccn .awssld__bullets button {
  height: 8px !important;
  width: 8px !important;
  outline: none !important;
}

.ccccn .awssld__bullets--active {
  background-color: #1e79e7 !important;
}

.ccccn .awssld__next {
  width: 7% !important;
}

.ccccn .awssld__prev {
  width: 7% !important;
}

.ccccn .awssld__controls__arrow-right {
  height: 20% !important;
}
.ccccn .awssld__controls__arrow-left:before,
.ccccn .awssld__controls__arrow-left:after,
.ccccn .awssld__controls__arrow-right:before,
.ccccn .awssld__controls__arrow-right:after {
  background-color: rgb(49, 56, 62) !important;
}
.ccccn .awssld__controls__arrow-left {
  height: 20% !important;
}

.ccccn .paginated-container {
  display: flex;
  flex: 1;
  padding: 8;
}

.ccccn .absolute-top-left {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.ccccn .absolute-top-right {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.ccccn .absolute-bottom-left {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ccccn .absolute-bottom-right {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.ccccn .awssld__bullets {
  width: 50% !important;
  margin-left: 25%;
  margin-right: 25%;
}
