.ccccn .Main-Video {
  min-width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.ccccn .button-bar {
  position: absolute;
  left: 0;
  bottom: 0;
}

.ccccn .stack-top {
  z-index: 9;
  margin: 5px;
}

.ccccn .thumb-container {
  right: 0;
  top: 0;
  z-index: 10;
  margin: unset;
  padding-inline-start: unset;
  display: inline;
}

.ccccn .cc-video-container {
  width: 25vh;
  height: 25vh;
  height: calc(100 * var(--vh));
}

.ccccn #main-video video {
  cursor: initial;
}

.ccccn .main-video-container {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: fixed; */
  /* transform: rotateY(180deg) */
}

.ccccn .main-video-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ccccn .main-video-avatar-center {
  /* height: 85vh; */
}

.ccccn .Main-Video-Mob {
  background-color: #000000;
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: fixed; */
}

.ccccn .thumb-video-container {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  border-radius: 0.7rem;
  justify-content: center;
}

.ccccn .thumb-mobile {
  width: 15vh;
  height: 15vh;
  margin-bottom: 5px;
}

.ccccn .Main-Video-Background {
  min-width: 100%;
  min-height: 100%;
}

.ccccn .change-mode {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.ccccn .grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@keyframes slide-out-right {
  from {
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    display: none;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slide-out-left {
  from {
    display: none;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.ccccn .slide-out-right {
  -webkit-animation-name: slide-out-right;
  animation-name: slide-out-right;
}

.ccccn .slide-out-left {
  -webkit-animation-name: slide-out-left;
  animation-name: slide-out-left;
}

.ccccn .animated {
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fade-in-down {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.ccccn .fade-in-down {
  -webkit-animation-name: fade-in-down;
  animation-name: fade-in-down;
}

@keyframes fade-in-up {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.ccccn .fade-in-up {
  -webkit-animation-name: fade-in-up;
  animation-name: fade-in-up;
}

.ccccn .Main-Video__bottom-user-name {
  position: absolute;
  z-index: 0;
  font-size: 14px;
  border-radius: 0.375rem;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  margin-left: 0.3rem;
  margin-bottom: 0.3rem;
  top: 0;
  right: 0;
}
@media (max-width: 400px) {
  .ccccn .Main-Video__bottom-user-name {
    left: 0px;
    margin-bottom: 0;
    margin-left: 0;
  }
  /* .local-video-stream video {
    width: 100px;
  } */
  .ccccn .local-video-stream {
    display: flex;
    justify-content: flex-end;
  }
}

.ccccn .local-video-stream {
  width: 200px;
}

.ccccn .popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.ccccn .popup .popup-text {
  width: 250px;

  background-color: #555;
  color: #fff;
  /* text-align: center; */
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}
.ccccn .popup .popup-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.ccccn .popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ccccn input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.ccccn input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #458b00;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.ccccn .grid-shadow {
  -webkit-box-shadow: 15px 0px 10px 5px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 15px 0px 10px 5px rgba(0, 0, 0, 0.41);
  box-shadow: 15px 0px 10px 5px rgba(0, 0, 0, 0.41);
}

.ccccn .lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ccccn .lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.ccccn .lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.ccccn .lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.ccccn .lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.ccccn .lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.ccccn .lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.ccccn .lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.ccccn .lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.ccccn .lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.ccccn .lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.ccccn .lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.ccccn .lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.ccccn .lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.ccccn .main-tile-container {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
}

.ccccn .main-tile-container-portrait {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.ccccn .tile-main-video-container {
  display: flex;
  flex: 1;
  height: 89%;
  margin-top: 5px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 5%;
  position: relative;
  justify-content: center;
  background-color: #000;
  border-radius: 10px;
}

.ccccn .tile-main-video-container-portrait {
  display: flex;
  width: 90%;
  height: 63%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  margin-top: 5%;
  position: relative;
  justify-content: center;
  background-color: #000;
  border-radius: 20;
}

.ccccn .tile-list-container-portrait {
  width: 100vw;
  height: 20%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0.5%;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow-x: auto;
}
.ccccn .tile-list-container {
  width: 15%;
  padding-left: 0%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 0.5%;
  overflow-y: auto;
}

.ccccn .local-spotlight-stream-container {
  width: 12vw;
}
.ccccn .grid-container {
  position: relative;
  display: flex;
  height: 100%;
  padding-left: 1px;
  padding-right: 1px;
  margin-left: 0.5%;
  margin-right: 0.5%;

  justify-content: center;
  align-items: center;
}
.ccccn .main-video-name {
  color: #fff;
  position: absolute;
  top: 5px;
  left: 5px;
}
.ccccn .screen-share-text {
  color: #fff;
}

.ccccn .aspect-ratio-1 {
  aspect-ratio: 1;
}

/* NEW UI  */
.ccccn .cc-main-container {
  /* height: 100vh;
  height: calc(100 * var(--vh));
  width: 100vw; */
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1c2226;
  position: relative;
}

.ccccn .main-container-recording-icon {
  color: red;
  margin-right: 4px;
}

@keyframes rec-blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.ccccn .main-container-recording-icon {
  text-decoration: blink;
  animation-name: rec-blinker;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

.ccccn .layout {
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1 1 0%;
  position: relative;
  flex-direction: row;
}

.ccccn .main-container-recording-text {
  color: rgb(255, 255, 255);
}

.ccccn .loading-container {
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.ccccn .reconnect-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #000000d0;
}

.ccccn .loading-text {
  margin-top: 8px;
  color: rgb(255, 255, 255);
}

.ccccn .scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ccccn .scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  overflow-y: auto; /* Firefox */
}

.ccccn .mainVideoContainer {
  /* height: calc(calc(100 * var(--vh)) - 80px); */
  height: calc(100% - var(--ccccn-bottom-button-container-height));
  display: flex;
  z-index: 0;
}

.ccccn .safari-video-container {
  min-width: 25vh;

  width: 0;
}

/* for mobile specific css */
@media (max-width: 600px) {
  .ccccn .recording-text-container {
    position: absolute;
    left: 16px;
    top: 16px;
    bottom: auto;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media (min-width: 600px) {
  .ccccn .recording-text-container {
    position: absolute;
    left: 16px;
    bottom: 24px;
    z-index: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.ccccn .cc-name-label {
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
